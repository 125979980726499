import _ from 'lodash';

export const generateGridLayout =(options={
	elWidth:670,
	columns:4,
	gutterPixelWidth:15,
	columnLayoutInfo:null,
	vAlign:'top',
	itemMap:[{

		vAlign: 'top',
		spans: 1,

		// the natural dimensions of the image
		width: 400,
		height: 400,

		// the separated dimensions of the content box and padding/border of the image itself
		innerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},

		// the separated dimensions of the content box and padding/border of the item surrounding the image itself
		outerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},
	}]
})=>{

	const {
		elWidth,
		columns,
		vAlign,
		itemMap,
	} = options;

	let {
		columnLayoutInfo,
		gutterPixelWidth,
	} = options;

	if( !columnLayoutInfo ){
		columnLayoutInfo = generateColumnMap(columns, gutterPixelWidth, elWidth);
	}

	const {
		columnWidth,
		halfPixels,
		columnAndGutterMap,
	} = columnLayoutInfo;

	gutterPixelWidth = columnLayoutInfo.gutterPixelWidth;

	const rowMap = [];
	let rowIndex = 0;
	let columnIndex = 0;

	itemMap.forEach((item, i) => {

		if(!rowMap[rowIndex]){
			rowMap[rowIndex] = {
				hasOrphans: false,
				items: [],
			};
		}

		let spans = item.spans || 1;

		// if grid span goes over, cut it down
		if( (columnIndex%columns) + spans > columns){
			spans = columns - (columnIndex%columns)
			item.spans = spans;
		}

		rowMap[rowIndex].items.push({...item});

		columnIndex = columnIndex+spans;

		if(columnIndex%columns == 0){
			rowIndex++;
		}

		// this row has orphaned items
		if( columnIndex%columns != 0 && i == itemMap.length-1){
			rowMap[rowMap.length-1].hasOrphans = true;
		}

	});


	rowMap.forEach((row, rowIndex)=>{

		let accumulatedColumns = 0;
		let accumulatedWidth = 0;

		row.items.forEach((item, columnIndex)=>{

			const spanWidth = item.spans || 1;
			
			let startColumn = accumulatedColumns;
			let endColumn = accumulatedColumns+spanWidth+-1;

			// use uniform column size to generate the initial pixel width to avoid getting different heights
			let pixelWidth = (columnWidth)*spanWidth + (gutterPixelWidth*(spanWidth-1));

			// use un-rounded value to generate height
			let scaleDown = (pixelWidth + -item.outerPad.horizPadSize + -item.innerPad.horizPadSize)/(item.width) || 1;
			
			let pixelHeight = Math.round((item.height) * scaleDown + item.innerPad.vertPadSize) || 300;

			// snap the actual width to the column and gutter map to make sure column alignments are consistent betweeen rows
			pixelWidth = columnAndGutterMap[endColumn].right - columnAndGutterMap[startColumn].left;

			accumulatedColumns += spanWidth;

			item.orphanedItem = row.hasOrphans;

			if ( columnIndex === row.items.length-1){
				item.lastColumn = true;

				if( item.index < itemMap.length-1){
					pixelWidth = elWidth - accumulatedWidth;
				}
				accumulatedWidth = 0;
			} else {
				accumulatedWidth = accumulatedWidth+ pixelWidth+gutterPixelWidth									
			}

			if (rowIndex == rowMap.length-1){
				item.lastRow = true;
			}

			item.columnIndex = columnIndex;

			item.pixelHeight = pixelHeight
			item.pixelWidth = pixelWidth;

		})

		let tallestHeight = _.maxBy(row.items, (item)=>item.pixelHeight).pixelHeight;

		row.items.forEach((item, columnIndex)=>{

			let marginTop = 0;
			let itemAlign = item.vAlign || vAlign || 'top';

			switch(itemAlign) {
				case 'top':
					marginTop = 0;
					break;
				case 'center':
					marginTop = (tallestHeight - item.pixelHeight)*.5
					break;
				case 'bottom':
					marginTop = tallestHeight - item.pixelHeight;	
					break;
			}

			item.marginTop = marginTop;		

		});

	});

	return rowMap;

}


export const generateJustifyLayout = (options={
	elWidth: 670,
	gutterPixelWidth: 15,
	rowPixelHeight: 250,
	rowAlign: 'fill', // each line can be left, right, center or 'fill'
	itemMap:[{

		// mark an item with this flag to designate it the end of a row
		rowEnd: false,

		// the natural dimensions of the image
		width: 400,
		height: 400,

		// the separated dimensions of the content box and padding/border of the image itself
		innerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},

		// the separated dimensions of the content box and padding/border of the item surrounding the image itself
		outerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},
	}]
})=>{

	const {
		elWidth,
		gutterPixelWidth,
		rowPixelHeight,
		rowAlign,
		itemMap,
	} = options


	const rowMap = [];
	const resolutionMultiplier = window.devicePixelRatio > 1 ? 2 : 1;

	let inDesignatedRow = false;

	itemMap.forEach(item=>{
		if( item.rowEnd || inDesignatedRow){
			item.inDesignatedRow = true;
			inDesignatedRow = true;
		} else {
			item.inDesignatedRow = false;
		}		
	});

	let cacheHits = 0;
	let newNodes = 0;
	const validRowCache={};
	const makeNodeMap = (index=0, parentRow={distance:0}, parentNode={distanceToFirstNode: 0, index: null}, map={} ) => {

		const distanceToFirstNode = parentRow.distance+parentNode.distanceToFirstNode;


		// only create a new node if the difference in distances is "drastic"
		if (map[index] && distanceToFirstNode < map[index].distanceToFirstNode && Math.abs(distanceToFirstNode - map[index].distanceToFirstNode) > .015  ){
	
			map[index] = null;
				
		}

		// if there is no node in the map yet, or this node is a shorter distance than the preexisting one
		// calculate its edges and add it to the map
		if ( !map[index]  ){

			const node = {
				index: parseInt(index),
				edges: {},
				distanceToFirstNode: distanceToFirstNode,
				parent: parentNode.index
			}

			if( validRowCache[node.index]){

				node.edges = validRowCache[node.index];
			} else {

				const startIndex = node.index;
				let breakIndex = node.index;
				let rowWidth = 0;
				let contentWidth = 0;
				let scaledWidth = 0;
				let scaledWidthNoItemPad = 0;
				let itemsInRow = 0;
				let midRowItem = null;

				for(let i = startIndex; i < itemMap.length; i++){

					const lastScaledWidth = scaledWidth;
					const lastScaledWidthNoItemPad = scaledWidthNoItemPad;

					const item = itemMap[i];
					const itemPad = item.innerPad.vertPadSize + item.outerPad.vertPadSize;
					const scale = (rowPixelHeight+-itemPad)/item.height;

					scaledWidthNoItemPad = item.width * scale;
					scaledWidth = scaledWidthNoItemPad + itemPad;

					// beginning of row
					if ( i === startIndex){
						itemsInRow = 1;
						rowWidth = scaledWidth;
						contentWidth = scaledWidth+-itemPad;
					} else {
						rowWidth = rowWidth + gutterPixelWidth + scaledWidth;
						contentWidth = contentWidth + scaledWidthNoItemPad;
						itemsInRow++;
					}

					// if next is new line
					if (item.rowEnd){

						node.edges[i+1] = {
							position: 'forcebreak',
							penalty: -9e9,
							itemCount: itemsInRow,
							startIndex: startIndex,
							breakBefore: i+1,
							rowWidth: rowWidth,
							contentWidth: contentWidth		
						}

						break;

					} else if ( rowWidth  >  elWidth) {

						if ( inDesignatedRow ){
							continue
						}
						
						node.edges[i] = {
							position: 'under',
							itemCount: itemsInRow+-1,
							penalty: 0,
							startIndex: startIndex,
							breakBefore: i,
							rowWidth: (rowWidth+-( gutterPixelWidth + scaledWidth)),
							contentWidth: (contentWidth-scaledWidthNoItemPad)
						}
					
						if( rowAlign ==='fill' || i === startIndex){
						
							// and then also hit the 'over'
							node.edges[i+1] = {
								position: 'over',
								penalty: 0,
								itemCount: itemsInRow,
								startIndex: startIndex,
								breakBefore: i+1,
								rowWidth: rowWidth,
								contentWidth: contentWidth						
							}

						} else if (i-1 > -1) {
						
							node.edges[i-1] = {
								position: 'oneunder',
								penalty: .11,
								itemCount: itemsInRow+-2,
								startIndex: startIndex,
								breakBefore: i-1,
								rowWidth: (rowWidth+-(gutterPixelWidth*2 + scaledWidth +lastScaledWidth )),
								contentWidth: (contentWidth-(scaledWidthNoItemPad+lastScaledWidthNoItemPad))					
							}
						}
						break;

					} else if(i == itemMap.length-1){
						
						if( rowAlign !=='fill' && i== startIndex){
							node.edges[i+1] = {
								position: 'orphan',
								penalty: 0.5,
								itemCount: itemsInRow,
								startIndex: startIndex,
								breakBefore: i+1,
								rowWidth: rowWidth,
								contentWidth: contentWidth						
							}	
						} else {
						
							// if it's the last, then we cut things off
							// with a slight penalty, because it's not the first choice		
							node.edges[i+1] = {
								position: 'last',
								penalty: 0.06,
								itemCount: itemsInRow,
								startIndex: startIndex,
								breakBefore: i+1,
								rowWidth: rowWidth,
								contentWidth: contentWidth
							}
						}

						break;

					} else if ( itemsInRow+-2 > 0 && !item.inDesignatedRow && rowAlign ==='fill'){
						

						// capture something in the middle of the row, just in case				
						midRowItem = {
							position: 'mid',
							penalty: 0.06,
							itemCount: itemsInRow+-2,
							startIndex: startIndex,
							breakBefore: i-1,
							rowWidth: (rowWidth+-(gutterPixelWidth*2 + scaledWidth +lastScaledWidth )),
							contentWidth: (contentWidth-(scaledWidthNoItemPad+lastScaledWidthNoItemPad))					
						}
						
					}

				}

				/*
				collate special case into the edges. this is mostly
				useful for galleries with relatively few items, where it would be
				more desirable to have two equal rows rather than one tiny and one
				huge row
				*/

				if (midRowItem){
					let midIndex = midRowItem.breakBefore;
					if ( !node.edges.hasOwnProperty(midIndex) && itemMap.length < 15){
						node.edges[midRowItem.breakBefore] = midRowItem;
					}
					delete node.edges.mid
				}

				/*
				calculate row penalty, which is calculated as a deviation from ideal, where the
				ideal row width fits perfectly into element width. penalties are added here.
				*/

				Object.keys(node.edges).forEach(key=>{

					const row = node.edges[key];
					let scale = row.rowWidth / elWidth;

					row.distance = Math.abs(1-scale)*Math.abs(1-scale)*Math.abs(1-scale);
					if ( !isNaN(row.penalty) ){
						row.distance = row.distance+row.penalty
					}

				});

				// store for later use
				validRowCache[startIndex] = node.edges;				

			}
			
			map[index] = node;

			Object.keys(node.edges).forEach(key=>{
				makeNodeMap(parseInt(key), node.edges[key], node, map)
			});
			
		} 

		return map; 

	}

	let map = makeNodeMap();

	const finalNodeMap = [];
	const sortedMapKeys = Object.keys(map).sort(function(a, b) {
	  return parseInt(a) - parseInt(b);
	});

	// start with final key, and cycle through parents to create final map
	let lastKey = sortedMapKeys[sortedMapKeys.length-1];
	while (lastKey != null){
		const node = map[lastKey];

		if ( map[node.parent] ){
			map[node.parent].path = map[node.parent].edges[lastKey];			
		}


		finalNodeMap.unshift(node)


		lastKey = node.parent;			
	}


	let orphanRowWidth = 0;

	finalNodeMap.forEach((node, index)=>{

		if (!node.path){
			return;
		}
		const path = node.path;
		const row = {
			hasOrphans: false,
			pixelHeight: rowPixelHeight,
			items: [],
		};

		let rowPxHeight = 0;
		let orphanRow = false;

		for(let i = path.startIndex; i < path.breakBefore; i++ ){

			const item = {...itemMap[i]};

			const rowLength = path.breakBefore - path.startIndex;
			const lastInRow = (i+1 == path.breakBefore);


			const vertPad = (item.innerPad.vertPadSize + item.outerPad.vertPadSize);
			const horizPad = (item.innerPad.horizPadSize + item.outerPad.horizPadSize);
			const scaleDown = (rowPixelHeight+-vertPad)/item.height || 1;

			let newWidth;
			let newHeight;

			newWidth = (item.width*scaleDown)+horizPad;
			newHeight = item.height*scaleDown+item.innerPad.vertPadSize;

			// width integrates media item and media pad
			// height only integrates media pad

			// item.width = item.width * scaleDown;
			// item.height = item.height * scaleDown;
			let remainder = elWidth +- path.rowWidth;

			if( rowAlign !== 'fill' || orphanRow ){
				remainder = Math.min(0, remainder)
				orphanRowWidth = path.rowWidth					

			// if the item has to expand above twice its width just to fill out a single row, zero-out the remainder
			// IF it's not a designated row
			} else if ( remainder / elWidth > .5  && lastInRow && index == 0 && !path.position =='forcebreak'){

				orphanRow = true;
				row.hasOrphans = true

				i = path.startIndex-1;
				continue
			}

			// if we have to scale the row up or down....
			if (remainder !== 0){

				// remainder is the amount of space left to fill
				// path.rowWidth is the width of the row as laid-out
				// path.contentWidth is the width of the row minus the gutters and padding

				/*
					first we compare the width of the item (no padding or gutters) to the overall width of the content in the row
					if the width is 20% of the total row-content at the base size, it should stay the same proprtion when scaled up
				*/
				const newWidthNoPad = newWidth+-horizPad;					
				const proportionOfWidth = newWidthNoPad/(path.contentWidth);	

				/*
					we just need to find out the size of the scaled content , which would be the element width
					minus all accumulated padding + gutters.
				*/
				const accumulatedPaddingAndGutters = path.rowWidth - path.contentWidth;
				const scaledContentWidth = elWidth - accumulatedPaddingAndGutters


				newWidth = proportionOfWidth*scaledContentWidth;
				newHeight = newWidth* ( item.height/item.width) + item.innerPad.vertPadSize
				newWidth = newWidth+horizPad;

			}

			// make sure widths are rounded to correct sisze

			newWidth = Math.floor(newWidth * resolutionMultiplier ) / resolutionMultiplier;							

			// row px height is rounded later in the function
			rowPxHeight = newHeight;

			if(i == path.startIndex){
				item.firstChild = true;
			}
			if(i+1 == path.breakBefore){
				item.lastChild = true;
			}

			if(index+2 == finalNodeMap.length){
				item.lastRow = true;
			}

			item.pixelWidth = newWidth;
			item.orphanRow = orphanRow;

			row.items.push(item);

		}

		if( orphanRow){
			row.orphanRowWidth = orphanRowWidth;
		}

		row.pixelHeight = Math.floor(rowPxHeight * resolutionMultiplier ) / resolutionMultiplier;			

		rowMap.push(row);
	});

	return rowMap;

	
}

export const generateColumnizedLayout = (options={

	elWidth:670,
	columns:3,
	gutterPixelWidth: 15,
	gutterPixelHeight:15,
	columnLayoutInfo:null,

	itemMap:[{

		spans: 1,

		// the natural dimensions of the image
		width: 400,
		height: 400,


		// the actual, in-browser dimensions of the item as it is rendered on the page
		renderedDimensions: {
			width: 213,
			height: 213,
		},

		// the separated dimensions of the content box and padding/border of the image itself
		innerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},

		// the separated dimensions of the content box and padding/border of the item surrounding the image itself
		outerPad: {
			horizPadSize: 0,
			vertPadSize: 0,
		},
	}]

})=>{

	const {
		elWidth,
		columns,
		itemMap,
		gutterPixelHeight,
	} = options;

	let {
		columnLayoutInfo,
		gutterPixelWidth,
	} = options;

	if( !columnLayoutInfo ){
		columnLayoutInfo = generateColumnMap(columns, gutterPixelWidth, elWidth);
	}

	const {
		columnWidth,
		halfPixels,
		columnAndGutterMap,
	} = columnLayoutInfo;

	gutterPixelWidth = columnLayoutInfo.gutterPixelWidth;



	let columnHeights = [];
	let lastAlignedInColumn = [];

	let columnMap = [];


	for(let i = 0; i < Math.min(itemMap.length, columns); i++ ){
		// shadowMap[i] = [];
		lastAlignedInColumn[i] = [];
		columnMap[i] = [];
	}
	for(let i = 0; i < columns; i++ ){
		columnHeights[i] = {height: 0, marginTop: -gutterPixelHeight, index: i};
	}

	
	const figureAttributes = [];

	const slottedStyleMap = [];

	itemMap.forEach((item, index)=>{

		let columnSpans = Math.min(item.spans, columns, itemMap.length);
		// the index of shortest column
		let colIndex = -1;
		const targetMarker = _.minBy(columnHeights, (marker)=>{

			colIndex++;

			// force multiple-span items to fit inside 
			// get current height of marker, then collect (column
			let columnHeightSlice = columnHeights.slice(colIndex+1, colIndex+columnSpans).map(marker=>marker.height);

			if( columnHeightSlice.length + 1 < columnSpans){
				return 9e9;
			}

			// const shortestAvailableColumnHeight = _.maxBy([marker.height, ...columnHeightSlice]);


			// using _.mean() means that if we have a set of two choices with identical taller heights, like
			// [158, 158] and [158, 0]
			// the 'mean' means that [158, 0] will be preferred as the minimum ( 158 vs , 79)
			// so that column items will 'gravitate towards' the slice with the lowest current height

			const columnBias = marker.height == 0? 0 : Math.abs((colIndex+(colIndex%2 == 0 ? .25 : .75 ) )*2-(columns));
			return _.mean([marker.height, ...columnHeightSlice]) + columnBias;
		});

		const slotName = `column-${targetMarker.index}-slot-${index}`;
		const currentTargetMarkerHeight = targetMarker.height;

		if(targetMarker.index+columnSpans > columns ){
			columnSpans = columns-targetMarker.index;
		}


		let height = 0;
		let frameHeight = null;
		let gridItemPixelWidth = (columnWidth)*columnSpans + (gutterPixelWidth*(columnSpans-1));


		height = item.renderedDimensions.height + item.outerPad.vertPadSize;

		let startColumn = targetMarker.index;
		let endColumn = targetMarker.index+columnSpans+-1;
		gridItemPixelWidth = columnAndGutterMap[endColumn].right - columnAndGutterMap[startColumn].left;

		let scaleDown = (gridItemPixelWidth + -item.outerPad.horizPadSize+ -item.innerPad.horizPadSize)/(item.width) || 1;

		frameHeight =  halfPixels ?
			Math.round( (item.height*scaleDown + item.innerPad.vertPadSize ) * 2 ) / 2  || NaN:
			Math.round( item.height*scaleDown + item.innerPad.vertPadSize ) || NaN;


		if( Number.isNaN(frameHeight) ){
			frameHeight = 300;
		}

		let tallestOverlappingColumnMarker = targetMarker;
		let tallestHeight = targetMarker.height
		for(let i = 1; i < columnSpans; i++){

			let nextColIndex = targetMarker.index+i;

			if( columnHeights[nextColIndex].height  >= tallestHeight ){
				tallestOverlappingColumnMarker = columnHeights[nextColIndex];
				tallestHeight = columnHeights[nextColIndex].height;
			}
		}

		const oldHeight = tallestOverlappingColumnMarker.height;
		const newHeight = tallestOverlappingColumnMarker.height + item.renderedDimensions.height+item.outerPad.vertPadSize+gutterPixelHeight;

		for(let i = 0; i < columnSpans; i++){

			let nextColIndex = targetMarker.index+i;
			if( i === 0){

				columnHeights[nextColIndex].marginTop = columnHeights[nextColIndex].marginTop + ( oldHeight - columnHeights[nextColIndex].height)
				columnHeights[nextColIndex].height = newHeight;
			} else {
				columnHeights[nextColIndex].marginTop = columnHeights[nextColIndex].marginTop + ( newHeight - columnHeights[nextColIndex].height)
				columnHeights[nextColIndex].height = newHeight;

			}
		}

		const columnItem = {
			...item,
			index: index,
		}


		let slottedStyleItem = {}
		
		let marginTop = columnHeights[targetMarker.index].marginTop;
		columnHeights[targetMarker.index].marginTop = 0;

		columnItem.marginTop = marginTop+gutterPixelHeight;
		columnItem.pixelWidth = gridItemPixelWidth;
		columnItem.pixelHeight = frameHeight;
		columnItem.firstInColumn = currentTargetMarkerHeight==0;

		if( !columnMap[targetMarker.index]) {
			columnMap[targetMarker.index] = [];
		}

		columnMap[targetMarker.index].push(columnItem);

	});


	columnMap.forEach(column=>{
		if( column[column.length-1]){
			column[column.length-1].lastInColumn = true;
		}
		column[column.length-1]
	})

	return columnMap;

}




export const generateColumnMap = (columns, gutterWidth, elWidth)=>{
	const divideByTwo = window.devicePixelRatio > 1;


	let columnWidth = (elWidth/columns) - gutterWidth*((columns-1)/columns);

	// make sure each column is at least one pixel wide
	columnWidth = Math.max(columnWidth, 1)

	let columnAndGutterMap = [];


	// add .001 to avoid float errors
	const isOverflowing = columnWidth * columns + gutterWidth * (columns-1) > elWidth+.001;
	if( isOverflowing){
		gutterWidth = Math.floor((elWidth/(columns-1)) - columnWidth);
	}
	let gutterPixelWidth = divideByTwo ? Math.round(gutterWidth * 2)/2 : Math.round(gutterWidth);

	for(let i = 0; i < columns; i++){

		const edge = {
			left: columnWidth * i + gutterWidth * i,
			right: columnWidth * i + gutterWidth * i + columnWidth
		}

		if( i < columns -1 ){
			edge.right = divideByTwo ? Math.round(edge.right*2)/2 : Math.round(edge.right)
		} else {
			edge.right = elWidth;
		}

		if( i > 0){
			edge.left = columnAndGutterMap[i-1].right+gutterPixelWidth	
		}
		
		columnAndGutterMap.push(edge);
	}

	return {
		columnAndGutterMap,
		columnWidth,
		gutterPixelWidth,
		halfPixels: divideByTwo
	};
}

